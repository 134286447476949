import React from 'react';
import PropTypes from 'prop-types';
import Layout from 'components/layout';
import { Container, Row, Col } from 'react-grid-system';
import Title from 'components/title';
// import Gallery from 'components/gallery';
import { graphql, Link } from 'gatsby';
import styled from 'styled-components';
import MEDIA from 'helpers/mediaTemplates';
import MarkdownWrapper from 'components/markdownwrapper';
import Img from 'gatsby-image';

export const CenterItems = styled.div`
  text-align: center;
`;

export const HeroBg = styled.div`
  margin-top: -4rem;
  position: relative;
  height: 30vh;
  min-height: 400px;
  overflow: hidden;

  ${MEDIA.MIN_DESKTOP`
    min-height: 700px;
  `};
`;

export const BtmList = styled.ul`
  display: flex;
  flex-direction: column;

  ${MEDIA.MIN_TABLET`
    flex-direction: row;
  `};

  li {
    flex-basis: auto;
  }

  li + li {
    margin-left: 2rem;
  }
`;

const AircraftMaintenance = ({ data }) => (
  <Layout>
    <HeroBg>
      <Img
        style={{
          position: 'absolute',
          left: 0,
          top: 0,
          width: '100%',
          height: '100%',
        }}
        fluid={data.heroBg.childImageSharp.fluid}
      />
    </HeroBg>
    <div style={{ height: '5vh' }} />
    <Container>
      <Row>
        <Col col={12} sm={12}>
          <CenterItems>
            {/* <Logo /> */}
            <div style={{ height: '1vh' }} />
            <Title as="h1" size="large">
              {data.aircraftmaintenanceJson.title}
            </Title>
          </CenterItems>
        </Col>
      </Row>
    </Container>
    {/* {data.aircraftmaintenanceJson.content.childMarkdownRemark.rawMarkdownBody} */}
    <div style={{ height: '5vh' }} />
    <Container>
      <Row>
        <Col col={12} sm={12}>
          <MarkdownWrapper>
            <div
              dangerouslySetInnerHTML={{
                __html:
                  data.aircraftmaintenanceJson.content.childMarkdownRemark.html,
              }}
            />
          </MarkdownWrapper>
        </Col>
      </Row>
    </Container>
    {/* <div style={{ height: '10vh' }} />
    <Gallery items={data.aircraftmaintenanceJson.gallery} /> */}
    <div style={{ height: '5vh' }} />
    <Container>
      <BtmList>
        <li>
          <Link to="/services" activeClassName="active">
            Services
          </Link>
        </li>
        <li>
          <Link to="/services/charter" activeClassName="active">
            Private Air Charter
          </Link>
        </li>
        <li>
          <Link to="/services/aircraft-maintenance" activeClassName="active">
            Aircraft Maintenance
          </Link>
        </li>
        <li>
          <Link to="/services/facility" activeClassName="active">
            Facility
          </Link>
        </li>
      </BtmList>
    </Container>
    <div style={{ height: '5vh' }} />
  </Layout>
);

AircraftMaintenance.propTypes = {
  data: PropTypes.object.isRequired,
};

export default AircraftMaintenance;

export const query = graphql`
  query AircraftMaintenanceQuery {
    aircraftmaintenanceJson {
      title
      content {
        childMarkdownRemark {
          html
          rawMarkdownBody
        }
      }
      gallery {
        title
        copy
        path
        image {
          childImageSharp {
            fluid(maxHeight: 500, quality: 90) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
    heroBg: file(relativePath: { eq: "snap/huron_clips_04.png" }) {
      childImageSharp {
        fluid(maxWidth: 3360, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;
